/* Services.css */

:root {
    background-color: var(--color-background);
}

.main-container {
    background: linear-gradient(to bottom right, #1d658f, #8fcef8);
    background-size: cover;
    background-position: center;
}

.services-container {
    padding: 20px;
    background-color: transparent;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
}

.services-container h1 {
    color: var(--color-white);
    margin-bottom: 20px;
}

.services-intro {
    color: var(--color-white);
    margin-bottom: 40px;
    text-align: center;
}

.service {
    background-color: var(--color-white);
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px var(--color-black-rgba);
}

.service h2 {
    color: var(--color-primary);
    margin-bottom: 20px;
}

.service p {
    color: var(--color-text);
    text-align: center;
}

@media (max-width: 768px) {}