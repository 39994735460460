/* Hero Section styles */

.hero-section {
    position: relative;
    background: url('../../assets/background-hero-section.webp') no-repeat center center;
    background-size: cover;
    color: var(--color-white);
    text-align: center;
    padding: 100px 20px;
}

.hero-content {
    position: relative;
    z-index: 2;
}

.hero-content h1 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-section::before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

.hero-content {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.hero-content h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.75);
    color: var(--color-white);
}

.subheading {
    font-size: 1.2rem;
    margin-bottom: 40px;
    padding: 0 20px;
}

.btn {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    cursor: pointer;
}

.btn-hero {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--color-primary);
    color: var(--color-header-background);
    font-weight: 400;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease-in-out;
}

.btn-hero:hover {
    background-color: var(--color-primary-dark);
}

.btn-secondary-hero {
    background-color: transparent;
    color: var(--color-white);
    font-weight: 400;
    border: 1px solid var(--color-white);
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease-in-out;
}

.btn-secondary-hero:hover {
    background-color: var(--color-primary);
    color: var(--color-header-background);
    border: 1px solid transparent;
}

/* Feature Section styles */

.feature-section {
    background-color: var(--color-background);
    padding: 20px 20px;
    text-align: center;
}

.feature-section h2 {
    color: var(--color-text);
    margin-bottom: 20px;
}

.feature-section p {
    color: var(--color-text-secondary);
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px; 
}

.feature-section .btn-feature {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--color-primary);
    color: var(--color-header-background);
    border: none;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease-in-out;
}

.feature-section .btn-feature:hover {
    background-color: var(--color-primary-dark);
}

/* Feature List Section styles */
.feature-list-section {
    padding: 20px 20px;
    max-width: 800px;
    margin: auto;
}

.feature-list-section h2 {
    color: var(--color-text);
    margin-bottom: 30px;
    text-align: center;
}

.permit-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.permit-item {
    background-color: var(--color-white);
    padding: 5px;
    box-shadow: 0 4px 8px var(--color-black-rgba);
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    gap: 10px
}

.permit-title-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.permit-icon {
    height: 30px;
    width: auto;
    margin: 10px
}

.permit-item h3 {
    color: var(--color-primary);
    margin-bottom: 20px;
}

.permit-item p {
    color: var(--color-text);
    margin-top: 0;
    padding: 0 20px 25px 20px; 
}

.feature-list-section h3 {
    color: var(--color-primary);
}

.feature-list-section p {
    color: var(--color-text);
    margin-bottom: 0;
}

@media (max-width: 768px) {
    /* Hero section responsive styling */

    .hero-content h1 {
        font-size: 1.75rem;
    }

    .subheading {
        font-size: 1rem;
    }

    /* Stack call-to-action buttons vertically on small screens */
    .hero-cta {
        flex-direction: column;
        gap: 10px;
    }

    /* Adjust padding and layout for mobile-friendly viewing */
    header,
    .feature-section,
    .call-to-action,
    .site-footer {
        padding-left: 15px;
        padding-right: 15px;
    }

    /* Update grid templates for features and testimonials to stack on small screens */
    .permit-list,
    .testimonials-container {
        grid-template-columns: 1fr;
    }
}