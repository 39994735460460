/* Testimonials Section Styling */
.testimonials-section {
  background-color: var(--color-background);
  padding: 30px 20px 60px 20px;
  text-align: center;
}

.testimonials-container {
  max-width: 800px;
  margin: auto;
  /* No need for grid styles here since Slider will take care of positioning */
}

.testimonial {
  background-color: var(--color-white);
  padding: 20px;
  margin: 5px;
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--color-black-rgba);
  /* Flex styles might not be necessary anymore, but you can keep them if they don't conflict with Slider */
}

.testimonial-quote {
  font-style: italic;
  color: var(--color-text);
  margin: 0 0 15px 0;
}

.testimonial-author {
  font-weight: bold;
  color: var(--color-text-secondary);
}

/* You might need to override some slick-slider styles to fit your design */
.slick-slide {
  /* Adjust padding or margins if needed */
}

.slick-dots {
  /* Style for the navigation dots */
}

/* Make sure to test responsiveness and adjust as necessary */


/* CTA Section Styling */

.call-to-action {
  background-color: var(--color-primary); /* A strong color for CTA to stand out */
  color: var(--color-header-background); /* White text color for readability */
  padding: 40px 0;
  text-align: center;
}

.call-to-action .cta-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 15px;
}

.call-to-action h2 {
  margin-bottom: 20px;
  color: var(--color-header-background); /* Ensuring the heading stands out on the CTA background */
}

.call-to-action p {
  margin-bottom: 30px;
  color: var(--color-header-background); /* Ensuring the text stands out on the CTA background */
}

.btn-cta {
  background-color: var(--color-white); /* White color for the button to stand out */
  color: var(--color-primary); /* Use the primary color for the text */
  padding: 10px 20px;
  border: 2px solid var(--color-primary); /* Solid primary color border for emphasis */
  border-radius: 5px;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
}

.btn-cta:hover {
  background-color: var(--color-primary); /* Primary color for the button on hover */
  color: var(--color-white); /* White text for contrast on hover */
  border-color: var(--color-primary-dark); /* Darker primary color for the border on hover */
}

/* FAQ Section Styling */

.faq-section {
  margin: 40px auto;
  padding: 10px 20px;
  max-width: 800px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
}

.faqs-container {
  display: flex;
  flex-direction: column;
}

.faq-item {
  border-bottom: 1px solid var(--color-border); /* Soft color for the border */
  background-color: var(--color-white); /* Light background for each item */
  padding: 15px 0;
  cursor: pointer;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.2em;
  color: var(--color-text); /* Dark color for questions */
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  padding-left: 20px;
  color: var(--color-text-secondary); /* Secondary color for answers */
}

.faq-item.open .faq-answer {
  max-height: 1000px; /* Adjust if necessary for your content */
}

.faq-toggle {
  font-size: 1.5em;
  padding-left: 10px;
  color: var(--color-primary); /* Primary color for the toggle icon */
}


@media (max-width: 768px) {

    /* FAQ Section Responsive Styling  */
    
    .faq-section {
        padding: 20px; /* Add more padding */
      }
    
      .faq-question, .faq-answer {
        font-size: 1rem; /* Reduce font size */
      }
}