/* About Us page styling */

:root {
  background-color: var(--color-background);
}

.main-container {
  background: linear-gradient(to bottom right, #8fcef8, #1d658f);
  background-size: cover;
  background-position: center;
}

.about-us-container {
  padding: 20px;
  background-color: transparent;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.intro-section,
.founder-section,
.history-section,
.team-section {
  margin-bottom: 30px;
  padding: 20px;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--color-black-rgba);
  text-align: center;
}

.intro-section h1,
.founder-section h2,
.history-section h2,
.team-section h2 {
  color: var(--color-primary);
}

.intro-section p,
.founder-section p,
.history-section p,
.team-section p {
  color: var(--color-text);
}

h1 {
  color: var(--color-white);
  margin-bottom: 40px;
}