* {
  box-sizing: border-box;
}

:root {
  /* Color scheme */
  --color-primary: #3888b6; /* Primary button color - A softer shade of blue */
  --color-primary-dark: #1b4d67; /* Primary button hover color - A darker shade of blue */
  --color-secondary: #4FAFA4; /* Secondary button and link color - A muted teal for a secondary accent */
  --color-secondary-dark: #3B7D7B; /* Secondary button and link hover color - A darker shade of teal */
  --color-background: #F8F8F8; /* Background color for sections - A very light gray that's easy on the eyes */
  --color-text: #3A3A3A; /* Primary text color - Dark gray for better readability */
  --color-text-secondary: #5A5A5A; /* Secondary text color - Slightly lighter gray for secondary text */
  --color-footer-background: #E8E8E8; /* Footer background color - Light gray that matches the header */
  --color-header-background: #E8E8E8; /* Header background color - Same as the footer for consistency */
  --color-white: #FFFFFF; /* White color for text and backgrounds */
  --color-black-rgba: rgba(50, 50, 50, 0.1); /* Black with opacity for shadows, softened */
  --color-overlay: rgba(50, 50, 50, 0.5); /* Overlay color - A translucent dark gray for overlays */
  --color-border: #D1D1D1; /* Border color for elements like FAQ - A soft gray that's not too stark */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}