/* Contact.css */

.contact-container {
    position: relative;
    background: url('../../assets/background-hero-section.webp') no-repeat center center;
    background-size: cover;
    padding: 50px 30px;
}

.contact-container::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6); /* Dark overlay */
    z-index: 1; /* Overlay above the background image but below the content */
}

/* Ensure that the form and all its children are positioned above the ::before pseudo-element */
.contact-form,
.contact-form *,
.thank-you-message,
.thank-you-message * {
    position: relative;
    z-index: 2; /* Above the overlay */
}

.contact-form {
    font-size: 0.9rem;
    max-width: 500px;
    margin: 0 auto;
    background-color: var(--color-white);
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px var(--color-black-rgba);
}

.input-group {
    margin-bottom: 10px;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
    color: var(--color-text);
}

.input-group input,
.input-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    margin-bottom: 10px;
}

.input-group textarea {
    height: 150px;
    /* Adjust the height as needed */
    resize: none;
}

.submit-button {
    background-color: var(--color-primary);
    color: var(--color-white);
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: var(--color-primary-dark);
}

.thank-you-message {
    max-width: 500px;
    margin: 0 auto;
    background-color: var(--color-white);
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px var(--color-black-rgba);
    /* You may want to adjust the positioning and sizing to better fit the longer content */
    position: relative;
    z-index: 2; /* Make sure it's above the overlay */
    text-align: center; /* Center the text for aesthetics */
  }  

.thank-you-message h2,
.thank-you-message p {
    position: relative;
    z-index: 2;
    /* Ensure the text is on top of the overlay */
}

.redirect-button {
    background-color: transparent; /* Keep transparent or use --color-background for a soft background */
    color: black;
    font-weight: 400;
    font-size: 1rem;
    border: 1px solid black;
    margin-top: 20px;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease-in-out;
}

.g-recaptcha {
    padding-bottom: 15px;
}


@media (max-width: 768px) {
    .contact-container {
        padding: 50px 15px;
    }

    .contact-form {
        padding: 20px;
    }
}