/* Footer Section Styling */

.site-footer {
    background-color: var(--color-footer-background); /* Light color as specified */
    color: var(--color-text); /* Dark text for readability */
    padding: 20px 0;
    font-size: 0.9em;
}

.footer-content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 200px));
    justify-content: center;
    max-width: 1200px;
    margin: auto;
    gap: 20px;
}

.footer-column h4 {
    font-size: 1.1em;
    margin-bottom: 15px;
    color: var(--color-text); /* Dark text for readability */
    text-align: center;
}

.footer-link {
    color: var(--color-text); /* Dark text for readability */
    text-decoration: none;
    text-align: center;
    margin-bottom: 10px;
    display: block;
}

.footer-link:hover {
    color: var(--color-primary); /* Primary color for hover effect */
    transition: color 0.3s ease-in-out;
}

/* Optional: Add social media icons with font-awesome or similar */
.footer-link.icon {
    font-size: 1.5em;
    margin-bottom: 0;
}


/* RESPONSIVE */

@media (max-width: 768px) {
    .footer-content {
        grid-template-columns: 1fr;
        text-align: center;
        gap: 5px;
    }

    .footer-link {
        margin-bottom: 5px;
    }

    .footer-link.icon {
        font-size: 1.2em;
    }

    .footer-content {
        align-items: center;
    }

    .footer-column h4 {
        text-align: center;
    }
}
