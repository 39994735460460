/* Header styles */
header {
  position: relative;
  z-index: 2;
  background-color: var(--color-header-background);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-text); /* Changed from white to dark text color */
}

.nav-toggle {
  display: none;
  padding: 10px 20px;
  /* Other styles */
}

.logo img {
  max-height: 60px; /* Adjust based on your header's size */
  width: auto; /* Maintains the aspect ratio */
  padding-top: 5px;
  padding-left: 10px;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav li {
  margin-left: 20px;
}

nav a {
  text-decoration: none;
  color: var(--color-text); /* Changed from white to dark text color */
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

nav a:hover {
  color: var(--color-primary);
}

/* Responsive design for mobile phones */
@media (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-left: 10px;
  }

  .logo img {
    order: -1;
    align-self: flex-start;
    padding-left: 5px;
    max-height: 50px;
    padding-top: 5px;
    margin-left: 0;
  } 

  nav {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.3s ease;
    background-color: var(--color-header-background);
  }

  .nav-toggle {
    display: block;
    position: absolute;
    right: 10px;
    z-index: 5;
    background: none;
    border: none;
    color: var(--color-text);
    font-size: 1.5em;
    cursor: pointer;
  }

  nav.visible {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 20px);
    padding-top: 20px;
    top: 60px;
    left: 0;
    background-color: var(--color-header-background);
  }

  nav ul {
    flex-direction: column;
    width: 100%;
  }

  nav li {
    text-align: center;
    padding: 0;
    margin: 0;
  }

  nav li:first-child {
    border-top: none;
  }

  nav a {
    display: block;
    padding: 10px;
    transition: background-color 0.3s ease;
  }

  nav a:hover {
    background-color: var(--color-primary); /* Subtle background color on hover */
    color: var(--color-white); /* White text on hover for contrast */
  }

}
